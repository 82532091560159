import React from "react"
import { Link } from "gatsby"

const BackToHome = () => (
  <div className="row justify-content-center">
    <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
      <Link to="/" className="mt-40 btn action-1">
        Back To Home
      </Link>
    </div>
  </div>
)

export default BackToHome
