import React from "react"
import BackToHome from "./back-to-home"

const ThankYou = ({ title, children }) => (
  <section className="bg-light pt-100 pb-150 text-center">
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
          <img srcSet={`${require("../i/ecommerce_40_img@2x.png")} 2x`} src={require("../i/ecommerce_40_img.png")} alt="" />
          <h2 className="mt-20 mb-20 f-32">{title}</h2>
          <div className="color-heading text-adaptive">{children}</div>
        </div>
      </div>
      <BackToHome />
    </div>
  </section>
)

export default ThankYou
