import React from "react"
import LayoutPage from "../../components/layout-page"
import SEO from "../../components/seo"
import ThankYou from "../../components/thank-you"
import ImageMeta from "../../components/ImageMeta"

const PluginThankYou = () => (
  <LayoutPage>
    <SEO title={"Thank you for your interest"} meta={ImageMeta}/>
    <ThankYou title={"Thank you for your interest in the Plug-in"}>
      We have added your email to the list of people interested in the plug-in version of mix:analog. We hope to be working with you soon as we open uo our beta testing team. Have
      a nice day!
    </ThankYou>
  </LayoutPage>
)

export default PluginThankYou
